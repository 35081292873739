import { Card, Layout, Page } from '@shopify/polaris'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { selectFormatter, selectShop, selectShopHasFeature } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { Rule, RuleAction, SubscriptionFeature } from 'gql'

import RuleEditForm from './RuleEditForm'

interface RulesPageProps {
  rule?: Rule | null
}

const RulesPage: FC<RulesPageProps> = ({ rule }) => {
  const fmt = _(selectFormatter)
  const navigate = useNavigate()
  const shop = _(selectShop)
  const showAdjustWindowAction = !!_(selectShopHasFeature(SubscriptionFeature.AdjustReturnWindows))
  const showApprovalActions = shop.useApprovals
  const showDoNotRequireShippingAction =
    shop.isNoShippingReturnEnabled || rule?.action === RuleAction.DoNotRequireReturnShipping

  return (
    <Page
      title={rule ? fmt('rules.editRule') : fmt('rules.createRule')}
      backAction={{ content: fmt('global.settings'), onAction: () => navigate(-1) }}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <RuleEditForm
              rule={rule}
              showAdjustWindowAction={showAdjustWindowAction}
              showApprovalActions={showApprovalActions}
              showDoNotRequireShippingAction={showDoNotRequireShippingAction}
              onComplete={() => navigate(-1)}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default RulesPage
export const RulesEditPage = RulesPage
