import { Loading } from '@shopify/polaris'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { useCommonData } from 'common/useCommonData'
import { useGetReturnRuleByIdQuery } from 'gql'

import { RulesEditPage } from './RulesEditPage'

export const RulesEditPageLoader: FC = () => {
  const params = useParams()
  const ruleId = params.ruleId ?? ''
  const [{ data, fetching, stale }] = useCommonData(useGetReturnRuleByIdQuery, {
    variables: { ruleId, includeRule: !!ruleId },
  })

  return (
    <>
      {(fetching || stale) && <Loading />}
      {data && <RulesEditPage rule={data.getReturnRule} />}
    </>
  )
}
