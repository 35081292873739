import { Loading, Page } from '@shopify/polaris'
import { FC, lazy, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { selectDataLoaded, selectFormatter, selectSubscriptionPlan } from 'store/global/global.selectors'
import { _ } from 'store/hooks'

import { PageRevealer } from 'common/PageRevealer'
import { useCommonData } from 'common/useCommonData'
import { useBillingQuery } from 'gql'
import { SkeletonSettings } from 'settings/SkeletonSettings'

import { PlanCardContextProvider } from './PlanCardContext'

const PlansPage = lazy(() => import('./PlansPage'))

type PlansPageLoaderProp = {
  force: boolean
}

export const PlansPageLoader: FC<PlansPageLoaderProp> = memo(function PlansPageLoader({ force }) {
  const fmt = _(selectFormatter)
  const navigate = useNavigate()

  const [{ data, fetching, stale }] = useCommonData(
    useBillingQuery,
    {
      requestPolicy: 'network-only',
    },
    'billing',
  )
  const loaded = _(selectDataLoaded('billing'))
  const shopPlan = _(selectSubscriptionPlan)

  return (
    <>
      {(fetching || stale) && <Loading />}
      {!loaded && <SkeletonSettings />}
      <PageRevealer reveal={loaded}>
        <PlanCardContextProvider>
          {/* Every shop has a plan, wait until it's loaded to show the page.  There are a lot of initial value cases
      that just work better if we don't set them until we get the shop's plan. */}
          {!fetching &&
            !stale &&
            loaded &&
            shopPlan.id &&
            shopPlan.id > 0 &&
            (shopPlan.forceSelectSubscriptionPlan ? (
              <PlansPage force={force} subscriptionPlans={data?.subscriptionPlans} />
            ) : (
              <Page
                title={fmt('settings.plans.title')}
                backAction={{ content: fmt('global.settings'), onAction: () => navigate('/config-settings') }}
                fullWidth
              >
                <PlansPage force={force} subscriptionPlans={data?.subscriptionPlans} />
              </Page>
            ))}
        </PlanCardContextProvider>
      </PageRevealer>
    </>
  )
})
