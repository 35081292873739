import {
  Bleed,
  BlockStack,
  Box,
  Button,
  Checkbox,
  Divider,
  FormLayout,
  InlineStack,
  Modal,
  Text,
  TextField,
} from '@shopify/polaris'
import { FormError, notEmpty as notEmptyValidator, useDynamicList, useField, useForm } from '@shopify/react-form'
import { FunctionComponent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { showToast } from 'store/global/global.slice'
import { _, useAppDispatch } from 'store/hooks'

import {
  ConditionInput,
  Rule,
  RuleAction,
  RuleActionOption,
  RuleAttribute,
  RuleInput,
  RuleOperator,
  RuleWhen,
  useReturnRuleCreateMutation,
  useReturnRuleDeleteMutation,
  useReturnRuleUpdateMutation,
} from 'gql'
import { getAttributeType } from 'myconstants'
import { notEmpty } from 'utils'

import ConditionRow from './ConditionRow'
import TakeActionRow from './TakeActionRow'

export interface EditRuleFormProps {
  rule?: Rule | null
  showAdjustWindowAction: boolean
  showApprovalActions: boolean
  showDoNotRequireShippingAction: boolean
  onComplete: () => void
}

const EditRuleModal: FunctionComponent<EditRuleFormProps> = ({
  rule,
  showAdjustWindowAction,
  showApprovalActions,
  showDoNotRequireShippingAction,
  onComplete,
}) => {
  const dispatch = useAppDispatch()
  const fmt = _(selectFormatter)
  const [, updateReturnRule] = useReturnRuleUpdateMutation()
  const [, createReturnRule] = useReturnRuleCreateMutation()
  const [{ fetching: deleting }, deleteReturnRule] = useReturnRuleDeleteMutation()

  const handleDeleteRule = async () => {
    if (rule?.id) {
      await deleteReturnRule({ id: rule.id })
      dispatch(showToast(fmt('rules.ruleDeleted')))
      return onComplete()
    }
  }

  // Conditions
  const emptyConditionFactory = (): ConditionInput => ({
    id: '',
    attr: RuleAttribute.None,
    op: null,
    value: '',
    question: null,
    questionChoices: [],
  })
  const {
    fields: conditionFields,
    value: conditions,
    addItem: addCondition,
    removeItem: removeCondition,
  } = useDynamicList(
    {
      list: rule?.conditions.filter(notEmpty).map((c) => ({
        ...c,
        uuid: undefined,
        question: c.question ?? '',
        questionChoices: c.questionChoices ?? [],
      })) ?? [emptyConditionFactory()],
      validates: {
        attr: (value) => {
          if (!value || value === RuleAttribute.None) return fmt('global.required')
        },
        op: notEmptyValidator(fmt('global.required')),
        value: (value, context) => {
          const trimmedValue = value.trim()

          // Check there's a value if it's not a question condition
          if (context.listItem.attr?.value !== RuleAttribute.QuestionAnswer && !trimmedValue)
            return fmt('global.required')

          // If it's got an attribute, validate accordingly
          if (context.listItem.attr?.value) {
            const type = getAttributeType(context.listItem.attr.value, context.listItem.op?.value)

            // Check it's a number ID
            if (type === 'number-id') if (isNaN(parseInt(trimmedValue))) return fmt('global.numberRequired')

            // Check it's an integer
            if (type === 'int')
              if (isNaN(parseInt(trimmedValue)) || parseFloat(trimmedValue) !== parseInt(trimmedValue))
                return fmt('global.integerRequired')

            // Check it's a non-negative integer
            if (type === 'uint')
              if (
                isNaN(parseInt(trimmedValue)) ||
                parseFloat(trimmedValue) !== parseInt(trimmedValue) ||
                parseFloat(trimmedValue) < 0
              )
                return fmt('global.nonNegativeIntegerRequired')

            // Check it's a non-negative number
            if (type === 'udecimal')
              if (isNaN(parseFloat(trimmedValue)) || parseFloat(trimmedValue) < 0)
                return fmt('global.positiveNumberRequired')
          }
        },
        questionChoices: (value, context) => {
          if (context.listItem.attr?.value === RuleAttribute.QuestionAnswer && !value?.length)
            return fmt('global.required')
        },
      },
    },
    emptyConditionFactory,
  )

  const { fields, submit } = useForm({
    fields: {
      conditions: conditionFields,
      name: useField({
        value: rule?.name ?? '',
        validates: [notEmptyValidator(fmt('global.required'))],
      }),
      when: useField(rule?.when ?? RuleWhen.And),
      action: useField({
        value: rule?.action,
        validates: [notEmptyValidator(fmt('global.required'))],
      }),
      actionOption: useField(rule?.actionOption),
      warehouseId: useField(rule?.warehouseId ?? ''),
      value: useField(rule?.value ? rule.value.toString() : ''),
      question: useField(rule?.question ?? ''),
      questionChoices: useField(rule?.questionChoices ?? []),
      message: useField(rule?.message),
    },
    onSubmit: async (data) => {
      const { action, actionOption, warehouseId, value, question, questionChoices, message } = data

      // Context-aware validation
      const errors: FormError[] = []
      switch (action) {
        case RuleAction.ShipToWarehouse:
          if (!warehouseId)
            errors.push({
              field: ['warehouseId'],
              message: fmt('editRule.selectWarehouse'),
            })
          break

        case RuleAction.DenyReturn:
          if (!actionOption)
            errors.push({
              field: ['actionOption'],
              message: fmt('global.required'),
            })
          if (actionOption === RuleActionOption.All && !message?.trim())
            errors.push({
              field: ['message'],
              message: fmt('editRule.provideMessage'),
            })
          break

        case RuleAction.AdjustWindow:
          if (!actionOption)
            errors.push({
              field: ['actionOption'],
              message: fmt('global.required'),
            })

          if (!value.trim())
            errors.push({
              field: ['value'],
              message: fmt('editRule.specifyDays'),
            })
          else if (isNaN(parseInt(value)))
            errors.push({
              field: ['value'],
              message: fmt('global.invalid'),
            })
          else if (parseInt(value) < 0)
            errors.push({
              field: ['value'],
              message: fmt('global.nonNegativeIntegerRequired'),
            })
          break

        case RuleAction.DenyShipping:
          if (!actionOption)
            errors.push({
              field: ['actionOption'],
              message: fmt('global.required'),
            })
          break

        case RuleAction.AllowReturnsUntilDate:
          if (!value)
            errors.push({
              field: ['value'],
              message: fmt('global.required'),
            })
          break

        case RuleAction.SetFee:
        case RuleAction.SetShippingFee:
          if (!actionOption)
            errors.push({
              field: ['actionOption'],
              message: fmt('global.required'),
            })

          if (!value.trim())
            errors.push({
              field: ['value'],
              message: fmt('global.required'),
            })
          else if (isNaN(parseFloat(value)))
            errors.push({
              field: ['value'],
              message: fmt('global.invalid'),
            })
          else if (parseFloat(value) < 0)
            errors.push({
              field: ['value'],
              message: fmt('global.nonNegativeNumberRequired'),
            })
          break

        case RuleAction.ShowQuestion:
          if (!question)
            errors.push({
              field: ['question'],
              message: fmt('global.required'),
            })
          break

        case RuleAction.ShowQuestionChoices:
          if (!questionChoices.length)
            errors.push({
              field: ['questionChoices'],
              message: fmt('global.required'),
            })
          break
      }

      // Fail if any errors
      if (errors.length) return { status: 'fail', errors }

      const variables: RuleInput = {
        id: rule?.id,
        ...data,
        conditions,
        message: message?.trim() ?? '',
        action: action ?? RuleAction.DenyReturn,
        actionOption: actionOption ?? RuleActionOption.All,
      }

      if (rule) await updateReturnRule({ returnRule: variables })
      else await createReturnRule({ returnRule: variables })
      dispatch(showToast(fmt('rules.ruleSaved')))
      onComplete()
      return { status: 'success' }
    },
  })

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <FormLayout>
      <Bleed marginBlock="200" marginInline="200">
        <TextField
          label={fmt('editRule.nameThisRule')}
          labelHidden
          name="name"
          type="text"
          inputMode="text"
          placeholder={fmt('editRule.nameThisRulePlaceholder')}
          autoComplete="off"
          variant="borderless"
          {...fields.name}
        />
      </Bleed>
      <Bleed marginInline="400">
        <Divider />
        <Box background="bg-fill-secondary" padding="400" paddingBlockStart="300">
          <BlockStack gap="300">
            <Text as="h3" variant="headingXs">
              <FormattedMessage id="editRule.conditions" defaultMessage="Conditions" />
            </Text>
            {conditionFields.map((condition, index) => (
              <ConditionRow
                key={index}
                condition={condition}
                addCondition={index === conditionFields.length - 1 ? () => addCondition() : undefined}
                removeCondition={conditionFields.length > 1 ? () => removeCondition(index) : undefined}
              />
            ))}
          </BlockStack>
        </Box>
        <Divider />
        {conditionFields.length > 1 && (
          <>
            <Box paddingInline="400" paddingBlock="300">
              <BlockStack gap="400">
                <Checkbox
                  label={
                    <FormattedMessage id="editRule.requireAllConditions" defaultMessage="All conditions must be met" />
                  }
                  helpText={
                    <>
                      {fields.when.value === RuleWhen.And ? (
                        <FormattedMessage
                          id="editRule.requireAllConditions.helpText.and"
                          defaultMessage="If checked, this rule will apply when <strong>all</strong> the conditions above are met."
                          values={{ strong: (content) => <strong>{content}</strong> }}
                        />
                      ) : (
                        <FormattedMessage
                          id="editRule.requireAllConditions.helpText.or"
                          defaultMessage="If not checked, this rule will apply when <strong>one or more</strong> of the conditions are met."
                          values={{ strong: (content) => <strong>{content}</strong> }}
                        />
                      )}
                    </>
                  }
                  name="when"
                  checked={fields.when.value === RuleWhen.And}
                  onChange={() => fields.when.onChange(fields.when.value === RuleWhen.And ? RuleWhen.Or : RuleWhen.And)}
                />
              </BlockStack>
            </Box>
            <Divider />
          </>
        )}
        <Box padding="400" paddingBlockStart="300">
          <BlockStack gap="300">
            <Text as="h3" variant="headingXs">
              {fmt('global.effect')}
            </Text>
            <FormLayout.Group>
              <TakeActionRow
                {...fields}
                showAdjustWindowAction={showAdjustWindowAction}
                showApprovalActions={showApprovalActions}
                showDoNotRequireShippingAction={showDoNotRequireShippingAction}
              />
            </FormLayout.Group>
            {fields.action.value === RuleAction.DenyReturn && (
              <FormLayout.Group>
                <TextField
                  name="ruleMessage"
                  type="text"
                  inputMode="text"
                  label={
                    <>
                      {fmt('editRule.ruleMessageLabel')}
                      {fields.actionOption.value !== RuleActionOption.All && ` (${fmt('global.optional')})`}
                    </>
                  }
                  autoComplete="off"
                  multiline={4}
                  helpText={
                    fields.actionOption.value !== RuleActionOption.All && (
                      <FormattedMessage
                        id="editRule.ruleMessage.helpText"
                        defaultMessage="If no message is provided, the disabled return type will be hidden entirely."
                      />
                    )
                  }
                  {...fields.message}
                />
              </FormLayout.Group>
            )}
          </BlockStack>
        </Box>
        <Divider />
      </Bleed>
      <InlineStack align="space-between" blockAlign="center" gap="400">
        <div>
          {rule?.id && (
            <>
              <Button onClick={() => setShowDeleteModal(true)} variant="plain" tone="critical">
                {fmt('global.delete')}
              </Button>
              <Modal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title={<FormattedMessage id="editRule.deleteReturnModal.title" defaultMessage="Delete rule?" />}
                titleHidden
                size="small"
                primaryAction={{
                  content: fmt('global.delete'),
                  destructive: true,
                  disabled: deleting,
                  loading: deleting,
                  onAction: handleDeleteRule,
                }}
                secondaryActions={[
                  {
                    disabled: deleting,
                    content: fmt('global.cancel'),
                    onAction: () => setShowDeleteModal(false),
                  },
                ]}
              >
                <Modal.Section>
                  <p>
                    <FormattedMessage
                      id="editRule.deleteReturnModal.body"
                      defaultMessage="Are you sure you want to delete this rule?"
                    />
                  </p>
                </Modal.Section>
              </Modal>
            </>
          )}
        </div>
        <InlineStack gap="200">
          <Button onClick={onComplete}>{fmt('global.cancel')}</Button>
          <Button submit onClick={submit} variant="primary">
            {fmt('global.save')}
          </Button>
        </InlineStack>
      </InlineStack>
    </FormLayout>
  )
}
export default EditRuleModal
