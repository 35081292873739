import { createContext, FC, memo, ReactNode, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const LATEST_RETURNS_PATH_STORAGE_KEY = 'rz-latest-returns-path'

type ReturnsContextProps = {
  backToReturns: () => void
}

export const ReturnsContext = createContext<ReturnsContextProps>({ backToReturns: () => {} })

export const ReturnsContextProvider: FC<{ children: ReactNode }> = memo(function ReturnsContextProvider({ children }) {
  const navigate = useNavigate()

  const location = useLocation()
  const path = location.pathname + location.search
  if (path === '/returns' || path.startsWith('/returns?'))
    window.sessionStorage.setItem(LATEST_RETURNS_PATH_STORAGE_KEY, path)
  const backToReturns = useCallback(
    () => navigate(window.sessionStorage.getItem(LATEST_RETURNS_PATH_STORAGE_KEY) ?? '/returns'),
    [navigate],
  )
  const value = useMemo(() => ({ backToReturns }), [backToReturns])

  return <ReturnsContext.Provider value={value}>{children}</ReturnsContext.Provider>
})
