import * as Sentry from '@sentry/react'
import '@shopify/polaris/build/esm/styles.css'
import PolarisProvider from 'PolarisProvider'
import { initGraphQLClientEmbedded, initGraphQLClientStandalone } from 'graphQLClient'
import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { StoreProvider } from 'store/StoreProvider'
import { selectRichFormatter } from 'store/global/global.selectors'
import { store } from 'store/store'
import { Provider as UrqlProvider } from 'urql'

import { setLoginAsUrl, setToken } from 'auth'
import { isStandalone } from 'common/isStandalone'
import { IntlProvider } from 'intl/IntlProvider'

import App from './App'
import { ENVIRONMENT, SENTRY_DSN, SENTRY_RELEASE } from './config'
import reportWebVitals from './reportWebVitals'
import { getShopifyVal } from './utils/shopify'

// import LogRocket from 'logrocket'

// import { IS_PRODUCTION } from 'config'

// import '@shopify/polaris/build/esm/styles.css'

// if (IS_PRODUCTION) {
// LogRocket.init(LOGROCKET_APP_ID)
// }

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  release: SENTRY_RELEASE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'Accept'],
})

const EmbeddedApp: React.FunctionComponent = () => {
  const [fmt, setFmt] = useState<ReturnType<typeof selectRichFormatter>>(selectRichFormatter(store.getState()))
  useEffect(() => {
    return store.subscribe(() => {
      const newFmt = selectRichFormatter(store.getState())
      if (newFmt !== fmt) setFmt(newFmt)
    })
  }, [fmt])
  const host = getShopifyVal('host')
  if (!host)
    return (
      <div>
        {fmt('allowCookies', {
          link: (content) => (
            <a target="_parent" href="https://support.google.com/chrome/answer/95647">
              {content}
            </a>
          ),
        })}
      </div>
    )
  return (
    <UrqlProvider value={initGraphQLClientEmbedded()}>
      <StoreProvider>
        <PolarisProvider>
          <IntlProvider>
            <App />
          </IntlProvider>
        </PolarisProvider>
      </StoreProvider>
    </UrqlProvider>
  )
}

const StandAloneApp: React.FunctionComponent = () => {
  // TODO(dmu) LOW: Make `StandAloneApp` DRY with `EmbeddedApp`
  // AppBridgeProvider will only work when embedded so this is
  // how we display app not embedded
  const client = initGraphQLClientStandalone()
  return (
    <UrqlProvider value={client}>
      <StoreProvider>
        <PolarisProvider>
          <IntlProvider>
            <App />
          </IntlProvider>
        </PolarisProvider>
      </StoreProvider>
    </UrqlProvider>
  )
}

const renderApp = () => {
  // This is to support the open in new window option which has a token
  const url = new URL(window.location.href)
  const urlToken = url.searchParams.get('token')
  const loginAsUrl = url.searchParams.get('loginAsUrl')
  if (urlToken) {
    setToken(urlToken)
    if (loginAsUrl) {
      setLoginAsUrl(loginAsUrl)
    }
  }
  const container = document.getElementById('root')
  const root = createRoot(container!)

  if (isStandalone()) root.render(<StandAloneApp />)
  else root.render(<EmbeddedApp />)
}
renderApp()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// Empty function is necessary to force web-vitals to load and allow app-bridge to
// log to shopify analytics
// reportWebVitals(() => {})
reportWebVitals()
