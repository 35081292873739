import {
  ActionList,
  Bleed,
  Box,
  Button,
  InlineError,
  InlineStack,
  Popover,
  Text,
  useBreakpoints,
} from '@shopify/polaris'
import { ArrowLeftIcon } from '@shopify/polaris-icons'
import { Field } from '@shopify/react-form'
import { FC, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter, selectQuestions } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled from 'styled-components'

import { ConditionInput } from 'gql'
import { notEmpty } from 'utils'

type QuestionConditionFieldProps = {
  questionField: Field<ConditionInput['question']>
  choicesField: Field<ConditionInput['questionChoices']>
}

export const QuestionConditionField: FC<QuestionConditionFieldProps> = ({ questionField, choicesField }) => {
  const fmt = _(selectFormatter)

  const clearQuestionSelection = useCallback(() => {
    questionField.onChange(null)
    choicesField.onChange([])
  }, [questionField])

  const [listOpen, setListOpen] = useState(false)
  const onClose = useCallback(() => {
    setListOpen(false)
    if (!choicesField.value?.length) clearQuestionSelection()
  }, [clearQuestionSelection, choicesField])

  const questions = _(selectQuestions)
  const questionOptions = useMemo(
    () =>
      questions?.map((q) => ({
        id: q.id!,
        content: q.text.trim(),
        active: questionField.value === q.id,
        onAction: () => questionField.onChange(q.id),
      })),
    [questionField, questions],
  )

  const choiceOptions = useMemo(
    () =>
      (questionField.value &&
        questions
          ?.find((q) => q.id === questionField.value)
          ?.choices?.filter(notEmpty)
          .map((c) => ({
            id: c.id!,
            content: c.text,
            active: choicesField.value?.includes(c.id),
            onAction: () =>
              choicesField.onChange(
                choicesField.value?.includes(c.id)
                  ? choicesField.value.filter((id) => id !== c.id)
                  : Array.from(new Set((choicesField.value ?? []).concat([c.id]))),
              ),
          }))) ||
      [],
    [questions, questionField.value, choicesField.value],
  )

  const selectedQuestionText = useMemo(
    () => questions?.find((q) => q.id === questionField.value)?.text.trim(),
    [questions, questionField.value],
  )

  const selectedChoicesText = useMemo(() => {
    const question = questions?.find((q) => q.id === questionField.value)
    if (!question) return question
    return choicesField.value?.map((id) => question.choices?.filter(notEmpty).find((c) => c.id === id)?.text)
  }, [questions, questionField.value, choicesField.value])

  const { mdDown } = useBreakpoints()

  return (
    !!questions?.length && (
      <Popover
        active={listOpen}
        autofocusTarget="container"
        activator={
          <ButtonWrapper>
            <Button
              size={mdDown ? 'medium' : 'large'}
              fullWidth
              pressed={listOpen}
              onClick={() => setListOpen(!listOpen)}
            >
              {
                (
                  <>
                    {selectedChoicesText?.length ? (
                      selectedChoicesText.length === 1 ? (
                        selectedChoicesText[0]
                      ) : (
                        <FormattedMessage
                          id="questionCondition.countAnswers"
                          defaultMessage="{count} Answers"
                          values={{
                            count: selectedChoicesText.length,
                          }}
                        />
                      )
                    ) : (
                      <Text as="span" tone="subdued">
                        {questionField.value ? (
                          <FormattedMessage id="questionCondition.noAnswer" defaultMessage="Select answers" />
                        ) : (
                          <FormattedMessage id="questionCondition.selectQuestion" defaultMessage="Select question" />
                        )}
                      </Text>
                    )}
                  </>
                ) as unknown as string
              }
            </Button>
            {choicesField.error && (
              <Box paddingBlockStart="100">
                <Text as="p" tone="critical">
                  <InlineError message={choicesField.error} fieldID="void" />
                </Text>
              </Box>
            )}
          </ButtonWrapper>
        }
        onClose={onClose}
        preferredPosition="above"
      >
        {!questionField.value?.length ? (
          <ActionList
            actionRole="menuitem"
            sections={[
              {
                title: (
                  <Bleed marginBlockEnd="150">
                    <Box paddingBlockStart="100" paddingInlineStart="100" paddingInlineEnd="200">
                      <strong>
                        <FormattedMessage id="questionCondition.selectQuestion" defaultMessage="Select question" />
                      </strong>
                    </Box>
                  </Bleed>
                ),
                items: questionOptions ?? [],
              },
            ]}
          />
        ) : (
          <ActionList
            actionRole="menuitem"
            sections={[
              {
                title: (
                  <Bleed marginBlockEnd="150">
                    <Box paddingBlock="100" paddingInlineEnd="200">
                      <InlineStack gap="200" blockAlign="center">
                        <BackButtonWrapper title={fmt('questions.backToQuestions')}>
                          <Button
                            accessibilityLabel={fmt('questions.backToQuestions')}
                            icon={ArrowLeftIcon}
                            variant="tertiary"
                            onClick={clearQuestionSelection}
                          />
                        </BackButtonWrapper>
                        <strong>{selectedQuestionText}</strong>
                      </InlineStack>
                    </Box>
                  </Bleed>
                ),
                items: choiceOptions,
              },
            ]}
          />
        )}
      </Popover>
    )
  )
}

const ButtonWrapper = styled.div`
  .Polaris-Button {
    white-space: nowrap;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

const BackButtonWrapper = styled.div`
  line-height: 0;
  align-self: center;
`
