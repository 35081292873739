import en from 'translations/en/admin.json'

export type LocaleMessages = typeof en
export type LocaleKey = keyof LocaleMessages

// Follow this for chinese https://stackoverflow.com/questions/4892372/language-codes-for-simplified-chinese-and-traditional-chinese

// Be sure to update polyfills.ts
export const supportedLocales = {
  // // Arabic translations are ar-LB but since they are similar to other versions of arabic
  // // I'm using the ar-LB for just plain ar too.
  // 'ar': () => import('translations/ar-LB/admin.json'),
  // 'ar-lb': () => import('translations/ar-LB/admin.json'),
  // cs: () => import('translations/cs/admin.json'),
  'de': () => import('translations/de/admin.json'),
  'en': null,
  'en-us': null,
  // 'en-gb': null,
  'es': () => import('translations/es/admin.json'),
  'fr': () => import('translations/fr/admin.json'),
  // it: () => import('translations/it/admin.json'),
  // nl: () => import('translations/nl/admin.json'),
  // 'pt': () => import('translations/pt-BR/admin.json'),
  // 'pt-br': () => import('translations/pt-BR/admin.json'),
  // zh: () => import('translations/zh-Hant/admin.json'),
}
export type LocaleId = keyof typeof supportedLocales

/**
 * Get the user's locale from the browser navigator
 * e.g. 'en-US' -> 'en-us'
 */
export const detectLocale = (): LocaleId => {
  return getLocaleKey(navigator.language) || 'en'
}

/**
 * Get the locale key from the list of supported locale if it exists
 * e.g. 'en-US' -> 'en-us'
 * e.g. 'en' -> 'en'
 * e.g. 'fr-FR' -> 'fr'
 */
export function getLocaleKey(locale?: string): LocaleId | null {
  if (!locale) return null

  // Lowercase since we are storing the locale in lower case format in the supportedLocales object
  const match = locale.toLowerCase()
  if (match in supportedLocales) return match as LocaleId

  // If the locale is not supported, try to match the first part of the locale en-US -> en
  const lang = locale.split('-')[0]
  if (lang in supportedLocales) return lang as LocaleId

  // If the locale is not supported
  return null
}

export interface TranslationMessage {
  id: keyof LocaleMessages
  values?: { [key: string]: any }
}

export const getLocaleLoader = (locale: LocaleId) => {
  const loc = locale.toLocaleLowerCase() as LocaleId
  const loader = Object.keys(supportedLocales).includes(loc)
    ? supportedLocales[loc]
    : supportedLocales[loc.slice(0, 2) as LocaleId]
  return loader
}
