/* eslint-disable no-template-curly-in-string */
/*
When we run the app as static site in docker we use envsubst to
substitute the key strings below with the actual environment variables.
See entrypoint.sh
*/
const getEnv = (key: string, defaultValue: string) => {
  let value: string = key
  if (key.indexOf('${') === 0) {
    value = defaultValue
  }
  return value
}

// Use these values when doing normal local development
const DEFAULT_ZAP_URL = 'http://api.rz.local'
const DEFAULT_SHOPIFY_API_KEY = '62a8a722403261a3cd8d3eaf4ac4cf27'

// Use these values when serving through ngrok
// const DEFAULT_ZAP_URL = 'https://returnzap.ngrok.io'
// const DEFAULT_SHOPIFY_API_KEY = '4ed760e001cc118e1698b088830800bb'

const DEFAULT_BEACON_ID = ''
// Use to test Beacon locally
// const DEFAULT_BEACON_ID = 'f2d72463-1842-44ab-9611-0487626409e2'

export const ZAP_URL = getEnv('${REACT_APP_ZAP_URL}', DEFAULT_ZAP_URL)
export const GRAPHQL_ENDPOINT = `${ZAP_URL}/graphql/admin`
export const PORTAL_GRAPHQL_ENDPOINT = `${ZAP_URL}/graphql/portal`
export const PORTAL_SRC = getEnv('${REACT_APP_PORTAL_SRC}', 'https://portal.returnzap.com/app.js')
export const SHOPIFY_API_KEY = getEnv('${REACT_APP_SHOPIFY_API_KEY}', DEFAULT_SHOPIFY_API_KEY)
export const ENVIRONMENT = getEnv('${REACT_APP_ENVIRONMENT}', 'dev')
export const SENTRY_DSN = getEnv('${REACT_APP_SENTRY_DSN}', '')
export const SENTRY_RELEASE = getEnv('${REACT_APP_SENTRY_RELEASE}', '')
export const BEACON_ID = getEnv('${REACT_APP_BEACON_ID}', DEFAULT_BEACON_ID)
export const INDEXED_DB_NAME = 'returnzap-v4'
export const LOGROCKET_APP_ID = getEnv('${REACT_APP_LOGROCKET_APP_ID}', 'lrq4le/returnzap-staging')
export const IS_PRODUCTION = ENVIRONMENT === 'production'

function localStorageAvailable() {
  const test = 'testLocalStorage'
  try {
    localStorage.setItem(test, test)
    const writtenValue = localStorage.getItem(test)
    localStorage.removeItem(test)
    return test === writtenValue
  } catch (e) {
    return false
  }
}

export const LOCALSTORAGE_IS_AVAILABLE = localStorageAvailable()

function sessionStorageAvailable() {
  const test = 'testLocalStorage'
  try {
    sessionStorage.setItem(test, test)
    const writtenValue = sessionStorage.getItem(test)
    sessionStorage.removeItem(test)
    return test === writtenValue
  } catch (e) {
    return false
  }
}

export const SESSIONSTORAGE_IS_AVAILABLE = sessionStorageAvailable()
