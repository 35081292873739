import { RuleAttribute, RuleOperator, ShopsPackageUnitChoices, ShopsPackageWeightUnitChoices } from 'gql'

// Named myconstants instead of constants cuz of node/no-deprecated-api

export const MEASURE_UNITS = [
  { label: 'cm', value: ShopsPackageUnitChoices.Cm },
  { label: 'in', value: ShopsPackageUnitChoices.In },
]
export const WEIGHT_UNITS = {
  [ShopsPackageWeightUnitChoices.Grams]: {
    label: 'g',
    value: ShopsPackageWeightUnitChoices.Grams,
  },
  [ShopsPackageWeightUnitChoices.Kilograms]: {
    label: 'kg',
    value: ShopsPackageWeightUnitChoices.Kilograms,
  },
  [ShopsPackageWeightUnitChoices.Ounces]: {
    label: 'oz',
    value: ShopsPackageWeightUnitChoices.Ounces,
  },
  [ShopsPackageWeightUnitChoices.Pounds]: {
    label: 'lb',
    value: ShopsPackageWeightUnitChoices.Pounds,
  },
}

export type AttributeType =
  | 'text'
  | 'number-id'
  | 'int'
  | 'uint' // Unsigned integer
  | 'udecimal' // Unsigned decimal
  | 'email'
  | 'date'
  | 'country'
  | 'return-type'
  | 'return-reason'
  | 'question-answer'
export const getAttributeType = (attr: RuleAttribute, op?: RuleOperator | null): AttributeType => {
  if (attr === RuleAttribute.CustomerCountry) return 'country'
  if (attr === RuleAttribute.CustomerEmail && (!op || [RuleOperator.Eq, RuleOperator.Neq].includes(op))) return 'email'
  if (
    [RuleAttribute.CustomerId, RuleAttribute.OrderId, RuleAttribute.ProductId, RuleAttribute.VariantId].includes(attr)
  )
    return 'number-id'
  if (
    [
      RuleAttribute.CustomerNumOrders,
      RuleAttribute.LineItemDaysSinceDeliveredAt,
      RuleAttribute.OrderDaysSinceOrdered,
      RuleAttribute.ReturnTotalQuantity,
      RuleAttribute.ReturnTotalWeightInGrams,
    ].includes(attr)
  )
    return 'uint'
  if (
    [
      RuleAttribute.CustomerLtv,
      RuleAttribute.LineItemCompareAtPriceDiscountPercent,
      RuleAttribute.LineItemDiscountPercent,
      RuleAttribute.OrderDiscountPercent,
      RuleAttribute.OrderTotal,
      RuleAttribute.ReturnTotalValue,
      RuleAttribute.VariantPrice,
      RuleAttribute.VariantWeight,
    ].includes(attr)
  )
    return 'udecimal'
  if ([RuleAttribute.CustomerTags, RuleAttribute.OrderTags, RuleAttribute.ProductTags].includes(attr)) return 'text'
  if (attr === RuleAttribute.OrderDate) return 'date'
  if (attr === RuleAttribute.ReturnType) return 'return-type'
  if (attr === RuleAttribute.QuestionAnswer) return 'question-answer'
  if (attr === RuleAttribute.ReturnReason) return 'return-reason'
  return 'text'
}
