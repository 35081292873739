import { Card, Select, Tabs, useBreakpoints } from '@shopify/polaris'
import { ArrowUpIcon } from '@shopify/polaris-icons'
import {
  CategoryScale,
  Chart,
  ChartArea,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  TooltipCallbacks,
} from 'chart.js'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'
import { selectFormatter, selectShopCurrency, selectShopInstallDate } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { DashboardQuery } from 'gql'
import { notEmpty, roundCents } from 'utils'

import { ChartName } from './ChartName'
import { RightAlignedTabs } from './RightAlignedTabs'
import { LARGE_CHART_PLACEHOLDER_COLOR } from './dashboard-values'

Chart.register(CategoryScale, LineController, LinearScale, PointElement, LineElement, Tooltip, Filler)

type ReturnsChartSlice = Exclude<
  Exclude<DashboardQuery['dashboardMetrics'], undefined | null>['returnsChart'],
  undefined | null
>

type Granularity = 'months' | 'weeks' | 'days' | 'hours'

const getInterpolationSamples = (
  granularity: Exclude<Granularity, 'months'>,
  beginning: boolean,
  startTime: number,
  endTime: number,
  anchor: 'end' | 'start' = 'end',
) => {
  let timeDistance: number
  switch (granularity) {
    case 'weeks':
      timeDistance = 1000 * 60 * 60 * 24 * 7
      break
    case 'days':
      timeDistance = 1000 * 60 * 60 * 24
      break
    case 'hours':
      timeDistance = 1000 * 60 * 60
      break
  }

  const samples = []
  let samplesBefore = Math.ceil((endTime - startTime) / timeDistance)
  if (!beginning) samplesBefore -= 1
  if (samplesBefore > 0) {
    const firstTime = anchor === 'end' ? endTime - timeDistance * samplesBefore : startTime + timeDistance
    for (let j = 0; j < samplesBefore; j++) {
      const additionalSampleTime = new Date(firstTime + timeDistance * j)
      samples.push({
        time: additionalSampleTime.getTime(),
        timestamp: additionalSampleTime.toISOString(),
        amount: 0,
        returns: 0,
        units: 0,
      })
    }
  }
  return samples
}

const getMonthInterpolationSamples = (beginning: boolean, startTime: number, endTime: number) => {
  const startDate = new Date(startTime)
  const endDate = new Date(endTime)
  const lastSampleMonths = startDate.getUTCMonth() + startDate.getUTCFullYear() * 12
  const sampleMonths = endDate.getUTCMonth() + endDate.getUTCFullYear() * 12
  const monthsDifference = sampleMonths - lastSampleMonths
  let samplesBefore = monthsDifference
  if (!beginning) samplesBefore -= 1
  const samples = []
  if (samplesBefore > 0) {
    const firstMonthDate = new Date(new Date(endTime).setUTCMonth(endDate.getUTCMonth() - samplesBefore))
    for (let j = 0; j < samplesBefore; j++) {
      const additionalMonthDate = new Date(new Date(firstMonthDate).setUTCMonth(firstMonthDate.getUTCMonth() + j))
      samples.push({
        time: additionalMonthDate.getTime(),
        timestamp: additionalMonthDate.toISOString(),
        amount: 0,
        returns: 0,
        units: 0,
      })
    }
  }
  return samples
}

type ReturnsChartProps = { since: Date; until: Date; chartsData: ReturnsChartSlice }

export const ReturnsChart: FC<ReturnsChartProps> = ({ since, until, chartsData }) => {
  const { formatNumber } = useIntl()
  const fmt = _(selectFormatter)
  const { smDown } = useBreakpoints()
  const { formatTime, formatDate } = useIntl()
  const currency = _(selectShopCurrency)

  const installDate = _(selectShopInstallDate)

  const [selected, setSelected] = useState<'returns' | 'units' | 'amount'>('returns')
  let selectedLabel: string
  switch (selected) {
    case 'returns':
      selectedLabel = ` ${fmt('global.returns')}`
      break
    case 'units':
      selectedLabel = fmt('returnsChart.unitsReturned', { units: '' })
      break
    case 'amount':
      selectedLabel = fmt('returnsChart.amountReturned', { amount: '' })
      break
  }
  const labelCallback: TooltipCallbacks<'line'>['label'] = useCallback(
    (context) =>
      `${
        selected === 'amount' ? formatNumber(context.parsed.y, { style: 'currency', currency }) : context.parsed.y
      }${selectedLabel}`,
    [selected, currency, selectedLabel],
  )

  const samplesWithTime = useMemo(
    () => chartsData.filter(notEmpty).map((s) => ({ ...s, time: new Date(s.timestamp).getTime() })),
    [chartsData],
  )

  const sinceTime = since.getTime()
  const untilTime = until.getTime()
  const timeRange = untilTime - sinceTime

  const granularity = useMemo(() => {
    if (timeRange <= 1000 * 60 * 60 * 24 * 180) return 'weeks'
    if (timeRange <= 1000 * 60 * 60 * 24 * 31) return 'days'
    if (timeRange <= 1000 * 60 * 60 * 24) return 'hours'
    return 'months'
  }, [timeRange])

  const samples = useMemo(() => {
    const samples: typeof samplesWithTime = []
    if (samplesWithTime.length) {
      for (let i = 0; i < samplesWithTime.length; i++) {
        const sample = samplesWithTime[i]

        const lastSampleTime = i ? samplesWithTime[i - 1].time : sinceTime
        if (granularity === 'months') samples.push(...getMonthInterpolationSamples(!i, lastSampleTime, sample.time))
        else samples.push(...getInterpolationSamples(granularity, !i, lastSampleTime, sample.time))

        samples.push(sample)
      }

      const lastSampleTime = samplesWithTime[samplesWithTime.length - 1].time
      if (granularity === 'months') samples.push(...getMonthInterpolationSamples(false, lastSampleTime, untilTime))
      else samples.push(...getInterpolationSamples(granularity, false, lastSampleTime, untilTime, 'start'))
    }
    return samples
  }, [samplesWithTime, sinceTime, granularity, untilTime])

  const showYearOnDates = useMemo(() => {
    const thisYear = new Date().getFullYear()
    return (
      (granularity === 'days' || granularity === 'weeks') &&
      (new Date(since).getFullYear() !== thisYear || new Date(until).getFullYear() !== thisYear)
    )
  }, [granularity])

  const labels = useMemo<string[]>(
    () =>
      granularity === 'months'
        ? samples.map((s) =>
            formatDate(s.timestamp, {
              year: 'numeric',
              month: 'short',
              timeZone: 'utc',
            }),
          )
        : granularity === 'weeks' || granularity === 'days'
          ? samples.map((s) =>
              formatDate(s.timestamp, {
                year: showYearOnDates ? 'numeric' : undefined,
                month: 'short',
                day: 'numeric',
                timeZone: 'utc',
              }),
            )
          : samples.map((s) =>
              formatTime(s.timestamp, {
                timeZone: 'utc',
              }),
            ),
    [granularity, samples, showYearOnDates],
  )

  const values = useMemo(() => {
    const installTime = new Date(installDate).getTime()
    const nowTime = new Date().getTime()
    return samples.map((s) => (s[selected] || (s.time >= installTime && s.time <= nowTime) ? s[selected] : null))
  }, [samples, selected])

  const nonNullValues = useMemo(() => values.filter(notEmpty), [values])
  const min = useMemo(() => Math.min(...nonNullValues), [nonNullValues])
  const max = useMemo(() => Math.max(...nonNullValues), [nonNullValues])
  const valueRange = max - min

  const padding = valueRange * 0.025
  const suggestedMin = min - padding > 0 ? min - padding : 0
  const suggestedMax = max + padding

  const start = nonNullValues[0]
  const now = nonNullValues[nonNullValues.length - 1]
  const total = useMemo(
    () => roundCents(nonNullValues?.reduce((total, value) => total + parseFloat(value), 0)),
    [nonNullValues],
  )
  const change = start ? now / start - 1 : null
  const changeColor = change === null || change === 0 ? 'neutral' : change > 0 ? 'bad' : 'good'

  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const [chart, setChart] = useState<Chart | null>(null)

  useEffect(() => {
    if (canvas) {
      const chart = new Chart(canvas, {
        type: 'line',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              padding: { x: 13, y: 12 },
              backgroundColor: '#ffffff',
              borderWidth: 1,
              borderColor: '#eaeaea',
              titleColor: 'rgb(31, 33, 36)',
              titleFont: { weight: '600' as any },
              bodyColor: 'rgb(31, 33, 36)',
              caretPadding: 8,
              caretSize: 8,
              cornerRadius: 3,
              displayColors: false,
              callbacks: {
                label: labelCallback,
              },
            },
          },
          layout: {
            autoPadding: true,
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              border: {
                display: false,
              },
              ticks: {
                maxRotation: 0,
                padding: 10,
                font: { size: 12 },
              },
              offset: true,
            },
            y: {
              border: {
                display: false,
              },
              grid: {
                color: '#F1F1F1',
                drawTicks: false,
              },
              ticks: {
                maxTicksLimit: 5,
                maxRotation: 0,
                padding: 15,
                font: { size: 12 },
              },
            },
          },
          interaction: {
            intersect: false,
            mode: 'nearest',
            axis: 'x',
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              fill: 'origin',
              borderWidth: 1.2,
              pointStyle: 'rectRot',
              pointBorderWidth: 0,
              pointBackgroundColor: '#2AACBB',
              pointRadius: 0,
              cubicInterpolationMode: 'monotone',
              borderColor: '#2AACBB',
              backgroundColor: function (context) {
                const chart = context.chart
                const { ctx, chartArea } = chart
                if (!(chartArea as ChartArea | undefined))
                  // This case happens on initial chart load
                  return
                const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
                gradient.addColorStop(1, '#2AACBB19')
                gradient.addColorStop(0, '#2AACBB00')
                return gradient
              },
            },
          ],
        },
      })
      setChart(chart)
      return () => chart.destroy()
    } else setChart(null)
  }, [canvas])

  useEffect(() => {
    if (chart) {
      chart.options.plugins!.tooltip!.callbacks!.label = labelCallback
      chart.options.scales!.y!.suggestedMin = suggestedMin
      chart.options.scales!.y!.suggestedMax = suggestedMax
      chart.data.labels = labels
      chart.data.datasets[0].data = values
      chart.update()
    }
  }, [chart, labels, values, suggestedMin, suggestedMax])

  const tabs = [
    {
      id: 'returns' as const,
      content: fmt('global.returns'),
    },
    {
      id: 'units' as const,
      content: fmt('global.units'),
    },
    {
      id: 'amount' as const,
      content: fmt('global.amount'),
    },
  ]

  return (
    <Container>
      <Card>
        <Header>
          <Texts>
            <Count>
              <FormattedNumber
                style={selected === 'amount' ? 'currency' : 'decimal'}
                currency={currency}
                value={total}
              />
            </Count>
            <ChartNameAndChange>
              <ChartName>
                {selected === 'returns' && fmt('global.returns')}
                {/* Using empty values here to provide a template that makes more sense during translation. */}
                {selected === 'units' && (
                  <FormattedMessage
                    id="returnsChart.unitsReturned"
                    defaultMessage="{units} units returned"
                    values={{ units: '' }}
                  />
                )}
                {selected === 'amount' && (
                  <FormattedMessage
                    id="returnsChart.amountReturned"
                    defaultMessage="{amount} returned"
                    values={{ amount: '' }}
                  />
                )}
              </ChartName>
              {!!change && (
                <Change $negative={change ? change < 0 : false} color={changeColor}>
                  <ArrowUpIcon />
                  {roundCents(Math.abs(change) * 100)}%
                </Change>
              )}
            </ChartNameAndChange>
          </Texts>
          {!!chartsData.length && (
            <Options>
              {smDown ? (
                <Select
                  label=""
                  labelHidden
                  value={selected}
                  options={
                    [
                      { label: fmt('global.returns'), value: 'returns' },
                      { label: fmt('global.units'), value: 'units' },
                      { label: fmt('global.amount'), value: 'amount' },
                    ] as const
                  }
                  onChange={(value: 'returns' | 'units' | 'amount') => setSelected(value)}
                />
              ) : (
                <RightAlignedTabs>
                  <Tabs
                    tabs={tabs}
                    selected={tabs.indexOf(tabs.find((t) => t.id === selected)!)}
                    onSelect={(index) => setSelected(tabs[index].id)}
                  />
                </RightAlignedTabs>
              )}
            </Options>
          )}
        </Header>
        {chartsData.length ? (
          <CanvasWrapper>
            <canvas ref={setCanvas} />
          </CanvasWrapper>
        ) : (
          <CanvasPlaceholder>
            <PlaceholderLine></PlaceholderLine>
            <PlaceholderLine></PlaceholderLine>
            <PlaceholderLine></PlaceholderLine>
            <PlaceholderText>
              <FormattedMessage id="returnsChart.noReturns" defaultMessage="No returns within this time frame" />
            </PlaceholderText>
          </CanvasPlaceholder>
        )}
      </Card>
    </Container>
  )
}

const Container = styled.div`
  grid-column: 1/-1;
`

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  gap: 2em;
`

const Texts = styled.div`
  display: flex;
  flex-flow: column;
  gap: 6px;
`

const Count = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const ChartNameAndChange = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4em;
`

const Change = styled.div<{ $negative: boolean; color: 'good' | 'neutral' | 'bad' }>`
  line-height: 1.4;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.2em;
  ${(p) =>
    p.color === 'good' &&
    css`
      color: var(--p-color-text-success);
    `}
  ${(p) =>
    p.color === 'bad' &&
    css`
      color: var(--p-color-text-critical);
    `}
  svg {
    fill: currentColor;
    height: 1em;
    ${(p) =>
      p.$negative &&
      css`
        transform: scaleY(-1);
      `}
  }
`

const Options = styled.div``

const CanvasWrapper = styled.div`
  margin-top: -4px;
  margin-left: -15px;
  height: 190px;
  position: relative;
  margin-bottom: -12px;
`

const CanvasPlaceholder = styled.div`
  margin-top: 10px;
  height: 164px;
  padding: var(--p-space-500);
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: var(--p-color-text-secondary);
  position: relative;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  background: #fafafa;
`

const PlaceholderLine = styled.div`
  position: absolute;
  left: 40px;
  right: 0;
  border-top: 1px solid #f1f1f1;
  &:nth-child(1) {
    top: 25%;
  }
  &:nth-child(2) {
    top: 50%;
  }
  &:nth-child(3) {
    top: 75%;
  }
`

const PlaceholderText = styled.div`
  border-radius: 1em;
  background: ${LARGE_CHART_PLACEHOLDER_COLOR};
  display: inline-block;
  position: relative;
  padding: 0.15em 1em;
`
