import { Loading } from '@shopify/polaris'
import { FC, useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useCommonData } from 'common/useCommonData'
import { Dashboard } from 'dashboard/Dashboard'
import { useDashboardQuery } from 'gql'

import { getToday } from './DateRangePicker'

// const Dashboard = lazy(() => import('dashboard/Dashboard'))

export const DashboardLoader: FC = () => {
  const navigate = useNavigate()

  const params = useParams()
  const dateRangeAliasParam = params.dateRangeAlias
  const sinceParam = params.since
  const untilParam = params.until

  const defaultDateRangeAlias = 'last-30-days'
  const today = useMemo(() => getToday(), [])
  const defaultSince = useMemo(() => new Date(new Date(today).setUTCDate(today.getUTCDate() - 30)), [])
  const defaultUntil = useMemo(() => today, [])

  const dateRangeAlias = useMemo(() => dateRangeAliasParam ?? defaultDateRangeAlias, [dateRangeAliasParam])
  const since = useMemo(() => (sinceParam ? new Date(sinceParam) : defaultSince), [sinceParam])
  const until = useMemo(() => (untilParam ? new Date(untilParam) : defaultUntil), [untilParam])

  const [result] = useCommonData(
    useDashboardQuery,
    {
      variables: {
        startDate: since.toISOString(),
        endDate: until.toISOString(),
      },
    },
    'dashboard',
  )

  const onDateRangeChange = useCallback(
    (newDateRangeAlias: string, newSince: Date, newUntil: Date) => {
      if (
        newDateRangeAlias !== dateRangeAlias ||
        newSince.getTime() !== since.getTime() ||
        newUntil.getTime() !== until.getTime()
      )
        navigate(`/dashboard/${newDateRangeAlias}/${newSince.toISOString()}/${newUntil.toISOString()}`)
    },
    [navigate, dateRangeAlias, since, until],
  )

  return (
    <>
      {(result.fetching || result.stale) && <Loading />}
      <Dashboard
        dashboardMetrics={result.data?.dashboardMetrics}
        since={since}
        until={until}
        dateRangeAlias={dateRangeAlias}
        onDateRangeChange={onDateRangeChange}
      />
    </>
  )
}
