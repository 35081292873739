import { Button, Icon, Select, TextField, useBreakpoints } from '@shopify/polaris'
import { MinusCircleIcon, PlusCircleIcon } from '@shopify/polaris-icons'
import { FieldDictionary } from '@shopify/react-form'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  selectCountryOptions,
  selectFormatter,
  selectReturnTypeOptions,
  selectShopReasons,
} from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import { selectGetValidOperators, selectRuleAttributeOptions } from 'store/rules/rules.selectors'
import styled, { css } from 'styled-components'

import { DateField } from 'common/DateField'
import { getAttributeType } from 'myconstants'
import { notEmpty } from 'utils'

import { QuestionConditionField } from './QuestionConditionField'

interface ConditionRowProps {
  condition: FieldDictionary<any>
  addCondition?: () => void
  removeCondition?: () => void
}

const ConditionRow: FunctionComponent<ConditionRowProps> = ({ condition, addCondition, removeCondition }) => {
  const fmt = _(selectFormatter)
  const ruleAttributesOptions = _(selectRuleAttributeOptions)
  const returnTypeOptions = _(selectReturnTypeOptions)
  const reasons = _(selectShopReasons)
  const countryOptions = _(selectCountryOptions)
  const localCountryOptions = useMemo(() => countryOptions.filter((co) => co.value), [countryOptions])
  const getValidOperators = _(selectGetValidOperators)
  const valueType = getAttributeType(condition.attr.value, condition.op.value)

  const { mdDown } = useBreakpoints()

  type Value = Parameters<typeof condition.attr.onChange>[0]
  const attrOnChange = useCallback(
    (value: Value) => {
      condition.attr.onChange(value)
      condition.value.onChange('')
    },
    [condition.attr.onChange],
  )

  const label = useMemo(() => {
    switch (valueType) {
      case 'text':
        return fmt('global.value')
      case 'number-id':
        return fmt('rules.ID.asInOrderId')
      case 'int':
      case 'uint':
      case 'udecimal':
        return fmt('global.number')
      case 'email':
        return fmt('global.email')
      case 'date':
        return fmt('global.date')
      case 'country':
        return fmt('global.country')
      case 'return-type':
        return fmt('global.returnType')
      case 'return-reason':
        return fmt('global.returnReason')
      case 'question-answer':
        return fmt('questions.questionAnswer')
    }
  }, [valueType, fmt])

  const textFieldType = useMemo(() => {
    switch (valueType) {
      case 'number-id':
      case 'int':
      case 'uint':
      case 'udecimal':
        return 'number'
      case 'email':
        return 'email'
      default:
        return 'text'
    }
  }, [valueType, fmt])

  return (
    <Condition $wrap={mdDown}>
      <Attribute $fullWidth={mdDown}>
        <Select
          label={<FormattedMessage id="conditionRow.ruleAttribute" defaultMessage="Rule attribute" />}
          labelHidden
          name="attr"
          options={ruleAttributesOptions}
          {...condition.attr}
          onChange={attrOnChange}
        />
      </Attribute>
      <Operation>
        <Select
          label={fmt('conditionRow.condition')}
          labelHidden
          placeholder={fmt('conditionRow.condition')}
          name="op"
          options={getValidOperators(condition.attr.value, valueType)}
          {...condition.op}
          // Necessary to set the value to empty string to select the label when there is no value
          value={condition.op.value ?? ''}
        />
      </Operation>
      <Value>
        {valueType === 'date' && <DateField label={label} labelHidden name="val" {...condition.value} />}
        {valueType === 'country' && (
          <Select
            label={label}
            labelHidden
            placeholder={label}
            name="val"
            options={localCountryOptions}
            {...condition.value}
          />
        )}
        {valueType === 'question-answer' && (
          <QuestionConditionField questionField={condition.question} choicesField={condition.questionChoices} />
        )}
        {valueType === 'return-type' && (
          <Select
            label={label}
            labelHidden
            placeholder={label}
            name="val"
            options={returnTypeOptions}
            {...condition.value}
          />
        )}
        {valueType === 'return-reason' && (
          <Select
            label={label}
            labelHidden
            placeholder={label}
            name="val"
            options={reasons?.filter(notEmpty).map((r) => r.reason) || []}
            {...condition.value}
          />
        )}
        {['text', 'number-id', 'int', 'uint', 'udecimal', 'email'].includes(valueType) && (
          <TextField
            type={textFieldType}
            label={label}
            labelHidden
            placeholder={label}
            min={valueType === 'uint' ? 0 : undefined}
            name="val"
            autoComplete="off"
            {...condition.value}
          />
        )}
      </Value>
      <Buttons>
        {removeCondition && (
          <div data-cy="removeRuleConditionButton">
            <Button
              icon={<Icon source={MinusCircleIcon} />}
              size="large"
              variant="tertiary"
              onClick={() => removeCondition()}
            />
          </div>
        )}
        {addCondition && (
          <div data-cy="addRuleConditionButton">
            <Button
              icon={<Icon source={PlusCircleIcon} />}
              size="large"
              variant="tertiary"
              onClick={() => addCondition()}
            ></Button>
          </div>
        )}
      </Buttons>
    </Condition>
  )
}

const Condition = styled.div<{ $wrap: boolean }>`
  display: flex;
  gap: var(--p-space-200);
  ${(p) =>
    p.$wrap &&
    css`
      flex-wrap: wrap;
    `}
`

const Attribute = styled.div<{ $fullWidth: boolean }>`
  width: 0;
  flex: 0 1 40%;
  ${(p) =>
    p.$fullWidth &&
    css`
      flex: 0 0 100%;
    `}
`
const Operation = styled.div`
  width: 0;
  flex: 0 1 170px;
  @media (max-width: 600px) {
    flex-basis: 90px;
  }
`
const Value = styled.div`
  width: 0;
  flex: 1 0 170px;
`

const Buttons = styled.div`
  margin-left: var(--p-space-100);
  margin-right: calc(var(--p-space-100) * -1);
  width: 0;
  flex: 0 0 56px;
  display: flex;
  gap: var(--p-space-300);

  .Polaris-Button {
    margin-top: 0;
    margin-bottom: 0;
    .Polaris-Icon {
      transform: scale(1.15);
    }
  }
`

export default ConditionRow
