import { TextField, TextFieldProps } from '@shopify/polaris'
import { FC } from 'react'
import styled from 'styled-components'

export const DateField: FC<
  Omit<TextFieldProps, 'type' | 'autoComplete'> & { autoComplete?: TextFieldProps['autoComplete'] }
> = ({ autoComplete = 'off', ...props }) => {
  return (
    <Styler>
      <TextField type="date" autoComplete={autoComplete} {...props} />
    </Styler>
  )
}

const Styler = styled.span`
  input {
    display: inline-block;
    line-height: 1;
  }
`
