import { selectOnboardingOpen, selectOnboardingPunted } from 'store/onboarding/onboarding.selectors'
import { setOnboardingInitialData, setOnboardingOpen } from 'store/onboarding/onboarding.slice'
import { AppThunk } from 'store/store'

import { deleteToken } from 'auth'
import { AuthenticatedShop, ShopifyUser } from 'gql'
import { LocaleId } from 'intl/intl'
import { identifyClarity } from 'utils/clarity'

import { selectPreferredLocale } from './global.selectors'
import { GlobalSlice, setError, setLoaded, setPreferredLocale, setUser, updateShop } from './global.slice'

type QueryData = { getMyShop?: Partial<AuthenticatedShop> | null; getMe?: ShopifyUser | null }
type QueryResult = { data?: QueryData; error?: any }

export const storeData =
  (queryResult: QueryResult, name?: GlobalSlice['loaded'][number]): AppThunk =>
  (dispatch, getState) => {
    const { data, error } = queryResult

    if (error) console.error(error)
    if (error?.message.includes('Signature verification failed')) deleteToken()

    const state = getState()
    if (data?.getMyShop) {
      if (selectOnboardingOpen(state) === undefined) {
        const onboardingPunted = selectOnboardingPunted(state)
        const onboardingStartsOpen = !onboardingPunted && !data.getMyShop.onboardingCompletedAt
        dispatch(setOnboardingOpen(onboardingStartsOpen))
      }
      if (name === 'onboarding') dispatch(setOnboardingInitialData(data.getMyShop))
      dispatch(updateShop(data.getMyShop))
    }

    if (data?.getMe) dispatch(setUser(data.getMe))
    if (data?.getMe?.preferredLocale && selectPreferredLocale(state) === undefined)
      dispatch(setPreferredLocale(data.getMe.preferredLocale as LocaleId))

    if (data?.getMyShop?.url && data.getMe?.email) identifyClarity(data.getMyShop.url, data.getMe.email)

    dispatch(setError(error?.message))
    if (data?.getMyShop && data.getMe) dispatch(setLoaded('common'))
    if (name && data && !error) dispatch(setLoaded(name))
  }
