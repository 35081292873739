import { BlockStack, Button, Layout, Page, Tabs, Text, useBreakpoints } from '@shopify/polaris'
import { FC, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { selectFormatter } from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import styled, { css } from 'styled-components'

import { Banners } from 'common/banners/Banners'
import { DashboardQuery } from 'gql'

import { ActionTakenChart } from './ActionTakenChart'
import { ActiveReturnsChart } from './ActiveReturnsChart'
import { DateRangePicker } from './DateRangePicker'
import { DefaultDashboardMetrics } from './DefaultDashboardMetrics'
import { MostReturned } from './MostReturned'
import { PolicyChart } from './PolicyChart'
import { ReasonChart } from './ReasonChart'
import { ReturnsChart } from './ReturnsChart'
import { RightAlignedTabs } from './RightAlignedTabs'
import { StageChart } from './StageChart'

type DashboardProps = {
  dashboardMetrics?: DashboardQuery['dashboardMetrics']
  dateRangeAlias: string
  since: Date
  until: Date
  onDateRangeChange: (dateRangeAlias: string, since: Date, until: Date) => unknown
}

export const Dashboard: FC<DashboardProps> = ({
  dashboardMetrics: _dashboardMetrics,
  dateRangeAlias,
  since,
  until,
  onDateRangeChange,
}) => {
  const { smDown, lgDown } = useBreakpoints()
  const fmt = _(selectFormatter)
  const dashboardMetrics = _dashboardMetrics ?? DefaultDashboardMetrics.dashboardMetrics
  const forcedLCPCode = useMemo(() => new Array(300).fill('&nbsp;').join(''), [])

  const [bottomMagnitude, setBottomMagnitude] = useState<'units' | 'amount'>('units')

  const tabs = [
    {
      id: 'units' as const,
      content: fmt('global.units'),
    },
    {
      id: 'amount' as const,
      content: fmt('global.amount'),
    },
  ]

  if (!dashboardMetrics || !dashboardMetrics.activeReturns) return <></>

  return (
    <Page>
      {/* Forced LCP element: */}
      <div
        style={{ position: 'absolute', pointerEvents: 'none' }}
        dangerouslySetInnerHTML={{ __html: forcedLCPCode }}
      ></div>
      <Banners banners={['missing-permissions', 'onboarding-pending', 'select-a-plan']} />
      <ActiveReturnsChart activeReturnsData={dashboardMetrics.activeReturns} />
      <Layout>
        <Layout.Section>
          <BlockStack gap="400">
            <AnalyticsBar $padding={smDown}>
              <Text variant="headingLg" as="h1">
                {fmt('global.analytics')}
              </Text>
              <DateRangePicker
                since={since}
                until={until}
                dateRangeAlias={dateRangeAlias}
                onChange={onDateRangeChange}
              />
            </AnalyticsBar>
            <Container columns={smDown ? 1 : lgDown ? 2 : 3}>
              <ReturnsChart since={since} until={until} chartsData={dashboardMetrics.returnsChart!} />
              <StageChart stageData={dashboardMetrics.stage!} />
              <MostReturned mostReturnedData={dashboardMetrics.mostReturned!} />
            </Container>
            <Divider />
            <RightAlignedTabs>
              <Tabs
                tabs={tabs}
                selected={tabs.indexOf(tabs.find((t) => t.id === bottomMagnitude)!)}
                onSelect={(index) => setBottomMagnitude(tabs[index].id)}
              />
            </RightAlignedTabs>
            <Container columns={smDown ? 1 : lgDown ? 2 : 3}>
              <PolicyChart data={dashboardMetrics.policy!} display={bottomMagnitude} />
              <ReasonChart data={dashboardMetrics.reason!} display={bottomMagnitude} />
              <ActionTakenChart data={dashboardMetrics.actionTaken!} display={bottomMagnitude} />
            </Container>
            <Footer>
              <FormattedMessage
                id="dashboard.feedback"
                defaultMessage="Enjoying our new dashboard? <link>Tell us how we can improve it.</link>"
                values={{
                  link: (content) => (
                    <Button url="mailto:support@returnzap.com" target="_blank" variant="plain">
                      {content as unknown as string}
                    </Button>
                  ),
                }}
              />
            </Footer>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default Dashboard

const Container = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: var(--p-space-400) var(--p-space-500);
  align-items: start;
  align-content: start;
`

const AnalyticsBar = styled.div<{ $padding: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(p) =>
    p.$padding &&
    css`
      padding: 0 var(--p-space-400);
    `}
`

const Footer = styled.div`
  margin-top: var(--p-space-800);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 70px;
    margin: 0 -35px;
    border-top: 1px solid var(--p-color-border-secondary);
  }
  padding-top: var(--p-space-1000);
  padding-left: var(--p-space-600);
  padding-right: var(--p-space-600);
  line-height: 1.6;
  color: var(--p-color-icon-secondary);
  text-align: center;
  a {
    color: inherit;
    &:link:hover {
      text-decoration: none;
    }
  }
`

const Divider = styled.div`
  grid-column: 1/-1;
  margin: var(--p-space-100) 0;
  height: 1px;
  background: var(--p-color-border-secondary);
`
