import { Select, TextField } from '@shopify/polaris'
import { Field } from '@shopify/react-form'
import { FC, useCallback, useMemo } from 'react'
import {
  selectFormatter,
  selectQuestions,
  selectRestockingFeeDefinedAsPercentage,
  selectShopHasFeature,
} from 'store/global/global.selectors'
import { _ } from 'store/hooks'
import {
  selectRuleActionOptionsReturnTypes,
  selectRuleActionOptionsShippingTypes,
  selectRuleActions,
} from 'store/rules/rules.selectors'
import styled from 'styled-components'

import { DateField } from 'common/DateField'
import { Maybe, RuleAction, RuleActionOption, SubscriptionFeature, useWarehousesQuery } from 'gql'

import { QuestionConditionField } from './QuestionConditionField'

const PLACEHOLDER_OPTIONS = {
  EMPTY: { label: '', value: '' },
}

interface TakeActionRowProps {
  action: Field<any>
  actionOption: Field<any>
  warehouseId: Field<any>
  value: Field<any>
  question: Field<string>
  questionChoices: Field<Maybe<string>[]>
  showAdjustWindowAction: boolean
  showApprovalActions: boolean
  showDoNotRequireShippingAction: boolean
}

const TakeActionRow: FC<TakeActionRowProps> = ({
  action,
  actionOption,
  warehouseId,
  value,
  question,
  questionChoices,
  showAdjustWindowAction,
  showApprovalActions,
  showDoNotRequireShippingAction,
}) => {
  const fmt = _(selectFormatter)

  const restockingFeeDefinedAsPercentage = _(selectRestockingFeeDefinedAsPercentage)

  const ruleActions = _(selectRuleActions)
  const ruleActionOptionReturnTypes = _(selectRuleActionOptionsReturnTypes)
  const ruleActionOptionShippingTypes = _(selectRuleActionOptionsShippingTypes)
  const [{ data }] = useWarehousesQuery()
  const warehouses = data?.warehouses

  const questions = _(selectQuestions)
  const questionOptions = useMemo(
    () =>
      questions?.map((q) => ({
        value: q.id!,
        label: q.text.trim(),
      })),
    [value, questions],
  )

  let actionSelectOptions = Object.values(ruleActions)
  if (!showAdjustWindowAction)
    actionSelectOptions = actionSelectOptions.filter(
      (a) => ![RuleAction.AdjustWindow, RuleAction.AllowReturnsUntilDate].includes(a.value),
    )

  if (!showApprovalActions && ![RuleAction.RequireApproval, RuleAction.DoNotRequireApproval].includes(action.value))
    actionSelectOptions = actionSelectOptions.filter(
      (a) => !(a.value == RuleAction.RequireApproval || a.value == RuleAction.DoNotRequireApproval),
    )

  if (!showDoNotRequireShippingAction && action.value !== RuleAction.DoNotRequireReturnShipping)
    actionSelectOptions = actionSelectOptions.filter((a) => a.value !== RuleAction.DoNotRequireReturnShipping)

  const shopHasQuestionsFeature = _(selectShopHasFeature(SubscriptionFeature.Questions))
  if (
    (!shopHasQuestionsFeature || !questions?.length) &&
    ![RuleAction.ShowQuestion, RuleAction.ShowQuestionChoices].includes(action.value)
  )
    actionSelectOptions = actionSelectOptions.filter(
      (a) => a.value !== RuleAction.ShowQuestion && a.value !== RuleAction.ShowQuestionChoices,
    )

  type ActionValue = Parameters<typeof action.onChange>[0]
  const actionOnChange = useCallback(
    (value: ActionValue) => {
      action.onChange(value)
      actionOption.onChange(null)
    },
    [action.onChange],
  )

  return (
    <Content>
      <Select
        label={fmt('takeActionRow.placeholder')}
        labelHidden
        placeholder={fmt('takeActionRow.placeholder')}
        name="action"
        options={actionSelectOptions}
        {...action}
        onChange={actionOnChange}
      />

      {[RuleAction.DenyReturn, RuleAction.AdjustWindow, RuleAction.AllowReturnsUntilDate].includes(action.value) && (
        <Select
          name="actionOption"
          label={fmt('global.returnType')}
          labelHidden
          placeholder={fmt('global.returnType')}
          options={Object.values(ruleActionOptionReturnTypes)}
          {...actionOption}
          value={actionOption.value ?? ''} // so we select empty label
        />
      )}

      {action.value === RuleAction.DenyShipping && (
        <Select
          name="actionOption"
          label={fmt('global.shippingMethod')}
          labelHidden
          placeholder={fmt('global.shippingMethod')}
          options={Object.values(ruleActionOptionShippingTypes)}
          {...actionOption}
          value={actionOption.value ?? ''}
        />
      )}

      {action.value === RuleAction.ShipToWarehouse && (
        <Select
          label={fmt('global.warehouse')}
          labelHidden
          placeholder={fmt('global.warehouse')}
          name="warehouseId"
          options={
            warehouses
              ? warehouses.map((w) => ({
                  label: w?.name ?? '',
                  value: w?.id ?? '',
                }))
              : Object.values(PLACEHOLDER_OPTIONS)
          }
          {...warehouseId}
        />
      )}

      {action.value === RuleAction.AdjustWindow && (
        <TextField
          label={fmt('global.days')}
          labelHidden
          name="value"
          type="integer"
          min={0}
          placeholder={fmt('global.days')}
          autoComplete="off"
          {...value}
        />
      )}

      {action.value === RuleAction.AllowReturnsUntilDate && (
        <DateField label={fmt('global.date')} labelHidden name="value" autoComplete="off" {...value} />
      )}

      {action.value === RuleAction.SetFee && (
        <>
          <Select
            name="actionOption"
            label={fmt('global.returnType')}
            labelHidden
            placeholder={fmt('global.returnType')}
            options={[
              {
                label: fmt('actionOptions.returnTypes.refunds'),
                value: RuleActionOption.Refund,
              },
              {
                label: fmt('actionOptions.returnTypes.exchanges'),
                value: RuleActionOption.Exchange,
              },
              {
                label: fmt('actionOptions.returnTypes.storeCredits'),
                value: RuleActionOption.Credit,
              },
              {
                label: fmt('actionOptions.returnTypes.allReturnTypes'),
                value: RuleActionOption.All,
              },
            ]}
            {...actionOption}
            value={actionOption.value ?? ''}
          />
          <TextField
            label={fmt('global.fee')}
            labelHidden
            placeholder={fmt('global.fee')}
            name="value"
            type="number"
            inputMode="decimal"
            autoComplete="off"
            min={0}
            suffix={restockingFeeDefinedAsPercentage ? '%' : undefined}
            {...value}
          />
        </>
      )}

      {action.value === RuleAction.SetShippingFee && (
        <>
          <Select
            name="actionOption"
            label={fmt('global.shippingMethod')}
            labelHidden
            placeholder={fmt('global.shippingMethod')}
            options={[
              {
                label: fmt('actionOptions.shippingTypes.prepaidShipping'),
                value: RuleActionOption.Free,
              },
              {
                label: fmt('actionOptions.shippingTypes.customerPaidShipping'),
                value: RuleActionOption.Paid,
              },
              {
                label: fmt('actionOptions.shippingTypes.customerSelfShip'),
                value: RuleActionOption.Self,
              },

              {
                label: fmt('actionOptions.shippingTypes.allShippingMethods'),
                value: RuleActionOption.All,
              },
            ]}
            {...actionOption}
            value={actionOption.value ?? ''}
          />
          <TextField
            label={fmt('global.fee')}
            labelHidden
            name="value"
            placeholder={fmt('global.fee')}
            autoComplete="off"
            type="number"
            inputMode="decimal"
            min={0}
            {...value}
          />
        </>
      )}

      {action.value === RuleAction.ShowQuestion && (
        <Select
          name="show-question"
          label={fmt('questions.question')}
          labelHidden
          placeholder={fmt('questions.question')}
          options={questionOptions}
          {...question}
          onChange={(val) => {
            question.onChange(val)
            questionChoices.onChange([])
          }}
        />
      )}
      {action.value === RuleAction.ShowQuestionChoices && (
        <QuestionConditionField questionField={question} choicesField={questionChoices} />
      )}
    </Content>
  )
}

export default TakeActionRow

const Content = styled.div`
  display: flex;
  gap: var(--p-space-400);
  > * {
    flex: 1 1 auto;
  }
`
